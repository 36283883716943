import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import {
  FeaturedPostLarge,
  FeaturedPostSmall,
} from 'components/pages/advice/featured-post';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { AdviceQuery } from 'graphqlTypes';
import {
  CategoryMenuLarge,
  CategoryMenuSmall,
  HeroLarge,
  HeroSmall,
  LatestArticlesLarge,
  LatestArticlesSmall,
  ShowMoreButton,
} from 'page-components/advice';
import {
  BottomCta,
  DesktopWrapper,
  MobileWrapper,
  TwoPanelNavigationWrapper,
} from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface AdviceProps {
  data: AdviceQuery;
}

const Advice: FC<AdviceProps> = ({ data }) => (
  <Layout>
    <PageWrapper background={colors.greenLight}>
      <Navigation />
      <DesktopWrapper>
        <HeroLarge />
        <TwoPanelNavigationWrapper
          rowGap="80px"
          columnGap="40px"
          navigationMenuWidth="23%"
          leftHandNavigationMenu={<CategoryMenuLarge data={data} />}
        >
          <FeaturedPostLarge data={data} />
          <LatestArticlesLarge data={data} />
          <ShowMoreButton />
        </TwoPanelNavigationWrapper>
      </DesktopWrapper>
      <MobileWrapper>
        <HeroSmall />
        <CategoryMenuSmall data={data} />
        <FeaturedPostSmall data={data} />
        <LatestArticlesSmall data={data} />
        <ShowMoreButton />
      </MobileWrapper>
      <BottomCta
        heading="Start getting your money ready for retirement."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export const query = graphql`
  query Advice {
    ...FeaturedPostQuery
    ...LatestArticlesQuery
    ...BlogCategoryMenuQuery
  }
`;

export default Advice;

export const Head = () => (
  <SEO
    title="Retirement Advice from Industry Experts | Retirable"
    description="Learn more about pensions, health care, retirement accounts, Social Security, lifestyle and more with advice to help you plan for a successful retirement."
    imagePath="advice-featured.png"
  />
);
