import { TextButton } from 'component-library/components/buttons/TextButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FeaturedPostQueryFragment } from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import React, { FC } from 'react';
import styled from 'styled-components';

const FeaturedPostWrapper = styled(Flex)`
  flex: wrap;
  gap: 0px 40px;
`;

const Section = styled(Flex)`
  width: 50%;
`;

interface FeaturedPostProps {
  data: FeaturedPostQueryFragment;
}

export const FeaturedPostLarge: FC<FeaturedPostProps> = ({ data }) => {
  const featuredPost = data.featuredBlogpost.nodes[0];
  const { title, excerpt, slug, primaryBlogCategory, image } = featuredPost;
  const titleWithContentVariablesReplaced = replaceContentVariables(
    title,
    data.contentVariables.nodes,
  );

  return (
    <LinkWrapper
      to={normalizeUrl(`${`/advice/${primaryBlogCategory?.title}/${slug}`}`)}
    >
      <FeaturedPostWrapper justifyContent="space-between">
        <Section>
          <GatsbyImage
            image={image?.gatsbyImageData}
            alt={titleWithContentVariablesReplaced || ''}
            style={{ borderRadius: 16, marginBottom: 24 }}
          />
        </Section>
        <Section column justifyContent="center">
          <ResponsiveHeading variant={4} marginBottom={16}>
            {titleWithContentVariablesReplaced}
          </ResponsiveHeading>
          <ResponsiveBody
            variant={3}
            dangerouslySetInnerHTML={{
              __html:
                replaceContentVariables(
                  excerpt?.childMarkdownRemark?.html,
                  data.contentVariables.nodes,
                ) || '',
            }}
            marginBottom={40}
          />

          <TextButton>Read More</TextButton>
        </Section>
      </FeaturedPostWrapper>
    </LinkWrapper>
  );
};
