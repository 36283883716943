import { TextButton } from 'component-library/components/buttons/TextButton';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FeaturedPostQueryFragment } from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import React, { FC } from 'react';
import styled from 'styled-components';

const FeaturedPostSmallWrapper = styled.div``;

interface FeaturedPostSmallProps {
  data: FeaturedPostQueryFragment;
}

export const FeaturedPostSmall: FC<FeaturedPostSmallProps> = ({
  data,
  ...rest
}) => {
  const featuredPost = data.featuredBlogpost.nodes[0];
  const { title, excerpt, slug, primaryBlogCategory, image } = featuredPost;
  const titleWithContentVariablesReplaced = replaceContentVariables(
    title,
    data.contentVariables.nodes,
  );
  return (
    <LinkWrapper
      to={normalizeUrl(`${`/advice/${primaryBlogCategory?.title}/${slug}`}`)}
    >
      <FeaturedPostSmallWrapper {...rest}>
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={titleWithContentVariablesReplaced || ''}
          style={{ borderRadius: 16, marginBottom: 24 }}
        />
        {primaryBlogCategory && (
          <ResponsiveHeading variant={4} marginBottom={16}>
            {titleWithContentVariablesReplaced}
          </ResponsiveHeading>
        )}
        <Body
          variant={3}
          marginTop={12}
          marginBottom={64}
          dangerouslySetInnerHTML={{
            __html:
              replaceContentVariables(
                excerpt?.childMarkdownRemark?.html,
                data.contentVariables.nodes,
              ) || '',
          }}
        />
        <TextButton>Read More</TextButton>
      </FeaturedPostSmallWrapper>
    </LinkWrapper>
  );
};
